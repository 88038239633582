<template>
  <div class="table_container">
    <v-btn fab class="ma-3 hide_on_print" @click="print"><v-icon>mdi-printer</v-icon></v-btn>
    <v-btn class="ma-3 hide_on_print" @click="toggleStatus">x</v-btn>
    <h2 class="hide_on_screen mb-7" style="margin-left:40%">
      <span v-if="globalCmdType == 2">Offre devis</span>
      <span v-else>Packing list</span>
    </h2>
    <h3><span class="hide_on_print">Client: </span><input v-model="client"/></h3>
    <table class="minimalistBlack  mt-2 " id="thedetailstable" style="table-layout: fixed;">
      <thead>
        <tr>
          <th id="ref_col" class="ref_print_width ref_screen_width">Référence</th>
          <th class="print_1_5 basic-width-reduced">Quantité</th>
          <th class="print_6 screen_600">Désignation</th>
          <th class="print_1_5 basic-width-reduced">P.U</th>
          <th class="hide_on_print basic-width">P.Promo</th>
          <th class="print_2 basic-width">Total HT</th>
          <th class="print_1_5 basic-width-reduced">#</th>
          <th class="print_1_5 hide_on_print basic-width-reduced">SC</th>
          <th class="print_1_5 hide_on_print basic-width-reduced">C</th>
          <th class="hide_on_print basic-width-reduced">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product,i) of products" :key="i">
          <td id="ref_col_td">
            <div>
               <input
                  style="width: 2.5cm"
                  onfocus="this.select()"
                  v-on:keyup.enter="fetchProdByRef"
                  class=""
                  v-model="product.reference"
                />
                <v-btn
                  style=""
                  text
                  class="hide_on_print"
                  @click.stop="showProductDetails(product)"
                >
                  <Magnify />
                </v-btn>
            </div>
          </td>
          
          <td class="ui-helper-center"><input onfocus="this.select()" v-on:keyup.enter="focusNewRefInput" :id="'uniteDeVente_'+i" class="width_1_5 hide_on_print red--text" v-model="product.quantity" ><span class="hide_on_screen red--text">{{product.quantity}}</span>
          </td>
          
          <td>
            <span class="hide_on_print" style="width:100%; font-size: 11px" >{{ product.des2 }}</span>
            <div  class="hide_on_screen">
              <span>{{ product.designation }}</span>
              <span v-if="product.reference != 'DIV'">
                <span style="font-size: 11px" >({{ product.dimensions }}) </span>
                <span v-if="product.marque || product.gamme" style="font-weight: 900;"> | </span>
                <span >{{ product.marque }} </span>
                <span>{{ product.gamme }} </span> 
              </span>
              
            </div>
            </td>
          
          <td class="ui-helper-center" style="font-weight: bold;"><input v-on:keyup.enter="focusNewRefInput"  :id="'prix_col_td_'+i" onfocus="this.select()" class="width_1_5 hide_on_print" v-model="product.prixUnitaire_tmp" ><span class="hide_on_screen">{{product.prixUnitaire_tmp}}</span></td>
          <td class="ui-helper-center hide_on_print basic-width">
              {{ product.prixPromo }}
          </td>

          <td class="ui-helper-center">{{safeMultiply(product.prixUnitaire_tmp, product.quantity)}}</td>
          <td class="ui-helper-center"><input v-on:keyup.enter="focusNewRefInput"  :id="'com_col_td_'+i" onfocus="this.select()" class="width_1_5 hide_on_print" v-model="product.commentaire" ><span class="hide_on_screen">{{product.commentaire}}</span></td>
          <td class="ui-helper-center hide_on_print">{{product.sous_colisage}}</td>
          <td class="ui-helper-center hide_on_print">{{product.colisage}}</td>
          <td class="hide_on_print"><v-btn text color="red" @click="deleteProductFromProducts(i)"><Delete/></v-btn></td>
        </tr>
        <tr class="hide_on_print">
          <td><input id="ref_input" class="ref_input" v-on:keyup.enter="fetchProdByRef" v-model="newRef"></td>
          <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
            <td><input type="text" class="basic-width" /></td>
        </tr>
      </tbody>
    </table>
    <p style="text-align:right" class="mr-7 mt-2">Total: {{products.map(x=> safeMultiply(x.quantity, x.prixUnitaire_tmp)).reduce((a,b) => safeAdd(a,b), 0)}} €</p>
    <v-dialog v-model="detailsDialog" >
      <DetailsProductComponent v-if="selectedProductForDetails" :product="selectedProductForDetails" />
    </v-dialog>
    <br>
    <v-btn class="mt-5 green hide_on_print" dark="" @click="validateOrder">valider changements</v-btn>
    <br/>
    <br/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DetailsProductComponent from '../components/DetailsProductComponent'
import Magnify from 'vue-material-design-icons/Magnify.vue';
import Delete from 'vue-material-design-icons/Delete.vue';
import axios from 'axios'
import bigDecimal from 'js-big-decimal'
import $ from "jquery";
import ArrowTable from '../utils/arrow-table'
export default {
  props: ['orderID'],
  //props: {cmdType: {default: 1}, orderID: String},
  data: () => ({
    newRef: "",
    selectedProductForDetails: null,
    products: [],
    client: ''
  }),
  components: {
    DetailsProductComponent,
    Magnify,
    Delete,
  },
  methods: {
    ...mapActions(['saveOrder']),
    ...mapMutations(['setProductDetailsDialog','addMessageAxios']),
    async fetchProdByRef() {
      //this.fetchProductsByReference({reference: this.newRef});
      axios.get(`${this.apiURL}/products/product/reference/${this.newRef}`)
        .then(response => {
          console.log(response.data)
          if(response.data != null) {
            this.addProductFromProduct(response.data)
          } else {
            console.log('product with ref ->', this.newRef, ' not found')
            //alert(`'${payload}' NOT FOUND !`)
            this.addMessageAxios({message: 'product not found', isError: true})
          }
        })
        .catch(error => {
          console.log('error fecthing prod by ref -> ', error)
          this.addMessageAxios({message: error.response.data, isError: true})
        })
      this.newRef = "";
    },
    focusNewRefInput() {
      setTimeout(() => window.scrollTo(0,document.body.scrollHeight), 0)
      let element = document.getElementById('ref_input')
      if(element != null) element.focus()
    },
    showProductDetails(product) {
      console.log(product.designation)
      this.selectedProductForDetails = product
      this.setProductDetailsDialog(true)
    },
    deleteProductFromProducts(i) {
      this.products.splice(i, 1)
      //localStorage.setItem('currentCommandeProducts', JSON.stringify(state.products))
    },
    validateOrder() {
      // eslint-disable-next-line no-constant-condition
      if(this.products.length > 0 || true) {
        this.saveOrder({client: this.client, id: this.orderID, items: this.products.map(prod => {
          return { 
            reference: prod.reference,
            quantity: prod.quantity, 
            current_price: prod.prixUnitaire_tmp,
            montantNet: this.safeMultiply(prod.quantity, prod.prixUnitaire_tmp),
            commentaire: prod.commentaire
          }
        }), total: this.products.map(x=> this.safeMultiply(x.quantity, x.prixUnitaire_tmp)).reduce((a,b) => this.safeAdd(a,b), 0)})
      }
      
    },
    async fetchCommandeDetails() {
      try {
        let orderRes = await axios.get(`${this.apiURL}/orders/orderWithItems/${this.orderID}`)
        console.log(orderRes.data)
        //this.products = orderRes.data.LocalOrderItems || []
        for(let orderItem of orderRes.data.LocalOrderItems) {
          //console.log(orderItem)
          this.addProduct(orderItem)
        }
        this.client = orderRes.data.user || ''
      } catch(err) {
        console.log(err)
        this.addMessageAxios({message: err.response.data, isError: true})
      }
    },
    addProduct(orderItem) {
      console.log('orderItem ->', orderItem)
      let prod = orderItem.product
      prod.commentaire = orderItem.commentaire
      prod.tva = 20.0
      prod.quantity = orderItem.quantity
      prod.prixUnitaire_tmp = orderItem.currentPrice
      prod.montantNet = this.safeMultiply(orderItem.currentPrice, orderItem.quantity)
      //console.log('prod ->', prod)
      //
      prod.des2 = ''
      if(prod.designation) {
        prod.des2 += ` ${prod.designation} `
      }
      if(prod.dimensions) {
        prod.des2 += ` (${prod.dimensions}) `
      }
      if(prod.marque || prod.gamme) {
        prod.des2 += ' | '
      }
      if(prod.marque) {
        prod.des2 += ` ${prod.marque} `
      }
      if(prod.gamme) {
        prod.des2 += ` ${prod.gamme} `
      }
      //
      this.products.push(prod)
    },
    addProductFromProduct(payload) {
      payload.commentaire = ''
      payload.tva = 20.0
      payload.quantity = payload.uniteDeVente
      //
      payload.des2 = ''
      if(payload.designation) {
        payload.des2 += ` ${payload.designation} `
      }
      if(payload.dimensions) {
        payload.des2 += ` (${payload.dimensions}) `
      }
      if(payload.marque || payload.gamme) {
        payload.des2 += ' | '
      }
      if(payload.marque) {
        payload.des2 += ` ${payload.marque} `
      }
      if(payload.gamme) {
        payload.des2 += ` ${payload.gamme} `
      }
      //
      payload.prixUnitaire_tmp = payload.prixA
      payload.montantNet = this.safeMultiply(payload.prixA, payload.uniteDeVente)
      console.log(payload)
      this.products.push(payload)
    },
    monter(lin, id) {
      lin--
      let element = document.getElementById(id+lin)
      if(element != null) element.focus()
    },
    descendre(lin, id) {
      lin++
      let element = document.getElementById(id+lin)
      if(element != null) element.focus()
    },
    safeMultiply(a,b) {
      return bigDecimal.multiply(a,b)*1
    },
    safeAdd(a,b) {
      return bigDecimal.add(a,b) * 1
    },
    print() {
      history.pushState({}, "", " ")
      window.print()
      history.back()
    },
    async toggleStatus() {
      try {
        await axios.patch(`${this.apiURL}/orders/toggle_status/${this.orderID}`)
        alert('ok')
      } catch(err) {
        console.error(err)
      }
    }
  },
  computed: {
    ...mapGetters(["erreurAjoutProduitFacture", 'productDetailsDialog', 'apiURL', 'globalCmdType']),
    detailsDialog: {
        get: function () {
          return this.productDetailsDialog
        },
        set: function (bool) {
          this.setProductDetailsDialog(bool)
        }
      },
    nbLines() {
      return this.products.length
    }
  },
  watch: {
    products() {
      //let newRef = maj.slice(-1)[0]
      //console.log(maj, ancien, ancien.length)
      //console.log('uniteDeVente_'+newRef.reference)
      console.log('uniteDeVente_'+(this.products.length-1))
      setTimeout(() => {
        let element = document.getElementById('uniteDeVente_'+(this.products.length-1))
        if(element != null) element.focus()
         window.scrollTo(0,document.body.scrollHeight)
        }, 0)
    },
    // eslint-disable-next-line no-unused-vars
    newRef(maj) {
      /*
      if(maj.length >= 13) {
        this.fetchProdByRef()
      }*/
    },
    productDetailsDialog(val) {
      if(!val) this.selectedProductForDetails = null
    }
  },
  updated() {
  },
  mounted() {
    this.focusNewRefInput()
    /* get products from localStorage */
    console.log(this.orderID)
    this.fetchCommandeDetails()

    // eslint-disable-next-line no-undef
    new ArrowTable($('#thedetailstable'), {
    enabledKeys: ['left', 'right', 'up', 'down']
    })
    //$('#thedetailstable').arrowTable({
    //enabledKeys: ['left', 'right', 'up', 'down']
    //});
  },
};
</script>

<style scoped>

@media screen {
  .basic-width {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
  .basic-width-reduced {
    width: 75px;
    min-width: 75px;
    max-width: 75px;
  }
  .hide_on_screen {
    display: none;
  }
  .totalCommande {
    position: fixed;
    bottom: 1em;
    right: 3em;
  }
  input {
  /*border-bottom: 0.5mm solid #00000033;*/
  transition: all 0.5s;
  }
.v-card {
  overflow: auto;
}
.width_1_5 {
  width: 1.5cm;
}
.ref_input {
  /*width: 3.1cm;*/
}
.ref_screen_width {
    width: 180px;
}
.screen_600 {
  width: 600px;
}
table.minimalistBlack {
  border: 1px solid #000000;
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}
table.minimalistBlack td,
table.minimalistBlack th {
  border: 0.5mm solid #000000;
  padding: 5px 4px;
}
table.minimalistBlack tbody td {
  font-size: 14px;
  text-align: center;
}
table.minimalistBlack thead {
  border: 0.5mm solid #000000;
}
table.minimalistBlack thead th {
  font-size: 16px;
  font-weight: bold;
  color: rgb(238, 97, 97);
  text-align: center;
}
#ref_col {
  /*text-align: left;*/
}
#ref_col_td {
  text-align: right !important;
}
table.minimalistBlack tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border: 1px solid #000000;
}
table.minimalistBlack tfoot td {
  /*font-size: 14px;*/
  border: 0.5mm solid black;

}
}

@media print {
  .ui-helper-center {
    text-align: center;
  }
  .table_container {
    padding-left: 0.7cm;
  }
  .ref_print_width {
    width: 3cm;
  }
  .hide_on_print {
    display: none;
  }
  .print_1_5 {
    width:1.5cm;
  }
  .print_2 {
    width:2cm;
  }
  .print_6 {
    width:9cm;
  }

.width_1_5 {
  width: 1.5cm;
}
.ref_input {
  width: 3.1cm;
}
table.minimalistBlack {
  width: 20cm;
  border: 1px solid #000000;
  text-align: left;
  border-collapse: collapse;
}
table.minimalistBlack td,
table.minimalistBlack th {
  border: 0.5mm solid #000000;
}
table.minimalistBlack tbody td {
  font-size: 14px;
}
table.minimalistBlack thead {
  border-bottom: 0.5mm solid #000000;
}
table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}
#ref_col {
  text-align: left;
}

table.minimalistBlack tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 1px solid #000000;
}
table.minimalistBlack tfoot td {
  /*font-size: 14px;*/
  border: 0.5mm solid black;

}
}
</style>